import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { bindEvent } from '@/_utils';
import { namespace } from 'vuex-class';
import { APPOINTMENT_NAMESPACE } from '@/_store/appointment';
import { IConfigAppointmentModule } from '@/_store/types';

const appointmentModule = namespace(APPOINTMENT_NAMESPACE);

@Component
export default class Home extends Vue {

  @appointmentModule.Action storeConfig!: (payload: IConfigAppointmentModule) => void;
  @appointmentModule.Getter getConfig!: IConfigAppointmentModule;
  @appointmentModule.Getter getError!: { message: string, times: number };

  get config() {
    return this.getConfig;
  }

  get error() {
    if (this.getError.times >= 4) {
      return true;
    }
    return false;
  }

  get errorMessage() {
    return this.getError.message;
  }

  mounted() {
    if (this.config.key !== '' && this.config.key !== undefined && this.config.key !== null && this.getError.times === 0) {
      this.$router.replace({
        name: 'open-appointment',
      })
    }
  }
}
